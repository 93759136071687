import {defineStore} from 'pinia';
import {ref, toValue, computed, markRaw} from "vue";
import {Config} from '@js-from-routes/client'
import {useApi} from "@/app/services/api";
import {useCable, useChannel, usePerform} from "@/app/services/cable";
import {omit} from "radash";
import {buildQueryString} from "~/app/utils";
import {useAuth} from "@/app/stores/auth";
import {useRoute} from "vue-router";
import mitt from "mitt";
import {useRequestState} from "@/app/composable/useRequestState";


export const defineChannelStore = (name, channelId) => {
  return defineStore(name, () => {
    // channel interface
    const $cable = useCable()
    const bus = markRaw(mitt())
    const identifier = ref()

    function subscribe(id, cb) {
      identifier.value = {...channelId, id}

      $cable.subscribe(identifier.value, {
        async received(data) {
          cb(Config.deserializeData(data))
        }
      })
    }

    function unsubscribe() {
      $cable.unsubscribe(identifier.value)
      bus.off('*')
    }

    return {
      subscribe,
      unsubscribe,
      emit: bus.emit,
      all: bus.all,
      off: bus.off,
      on: bus.on
    }
  })
}

export const usePromoChannelStore = defineStore('promotionChannel', () =>{
  // channel interface
  const $cable = useCable()
  const bus = markRaw(mitt())
  const channelId = ref()

  function subscribe(id) {
    channelId.value = {
      channel: 'PromotionsChannel',
      id: id
    }

    $cable.subscribe(channelId.value, {
      async received({eventType, detail}) {
        console.log('EVENT TYPE', eventType)
        await bus.emit(eventType, Config.deserializeData(detail))
      }
    })
  }

  function unsubscribe() {
    console.log('UNSUBSCRIBE')
    bus.off('*')
    $cable.unsubscribe(channelId.value)
  }


  return {
    subscribe,
    unsubscribe,
    channelId,
    bus,
    off: bus.off,
    on: bus.on
  }
})


export const userChannelStore = defineChannelStore('useChannel', {channel: 'UserChannel'})