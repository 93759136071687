<script setup>

import PromotionForm from "@/app/views/promotions/PromotionForm.vue";
import {useRoute, useRouter} from "vue-router";
import {ref, watch} from "vue";
import {usePromotions} from "@/app/stores/promotion";
import {useDevtools} from "@/app/composable/useDevtools";

const devtools = useDevtools()
const route = useRoute()
const router = useRouter()
const promo = ref({})
const store = usePromotions()

watch(
    () => route.params,
    async () => {
      const data = await store.show(route.params.id)
      promo.value = data.promotion
    },
    { immediate: true }
)

const updatePromotion = async (promo) => {
  console.log('update', promo);
  devtools.emit('promotions.update', {data: {promo}})
  const update = {
    id: promo.id,
    title: promo.title,
  
    slug: promo.slug,
    start_at: promo.start_at,
    end_at: promo.end_at,
    active: promo.active,
    contentUrl: promo.contentUrl
  };
  const res = await store.update(update);
}

const cancelUpdate = () => {
  router.push(`/promotions/${route.params.id}`)
}

</script>

<template>
<div class="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">

  <router-link to="/promotions"> Back </router-link>

  <div class="w-full border-t border-gray-300" />

  <PromotionForm v-model="promo"
                 @update:modelValue="updatePromotion"
                 @cancel="cancelUpdate()"
  />
</div>
</template>

<style scoped>

</style>