import {defineStore} from "pinia";
import {inject, ref} from "vue";
import {useApi} from "@/app/services/api";
import {useEvents} from "@/app/stores/event";
import {useChannel} from "@/app/services/cable";
import {useRoute} from "vue-router";
import {useCollection} from "@/app/composable/useCollection";
import {useRequestState} from "@/app/composable/useRequestState";


export const promotionStore = defineStore('promo', () => {
  const promo = ref({
    title: '',
    id: '',
    slug: '',
    gid: '',
  })

  // const events = useCollection('event')
  const $api = useApi()
  // const $eventApi = $api.apiV1PromotionsEvents
  const route = useRoute()

  async function load() {
    const res = await $api.apiV1Promotions.show({params: {id: route.params.slug}})

    promo.value = res.promotion

    // await $eventApi.index({
    //   params: {
    //     promotionId: res.promotion.slug
    //   }
    // }, events._updateAll)

    // console.log(events.data)
  }

  // async function createEvent(event) {
  //   console.log($eventApi)
  //   try {
  //     return await $eventApi.create({data: {event}}, events._push)
  //   } catch(e) {
  //     console.log('SOME ERROR', e)
  //   }
  // }

  return {
    promo,
    load,
    // events: events.data,
    // createEvent,
    // updateEvent: events._update
  }
})


export const createPromotionStore = (id) => {

  const usePromotion = defineStore(`promotion:${id}`, () => {
    const $api = useApi()
    const $endpoint = $api.apiV1PromotionsEvents
    const promotion = ref({})
    const events = ref([])
    const channelId = {
      channelName: 'PromotionChannel',
      id
    }

    // this gets unsubed on un mount
    useChannel({
      ...channelId,
      handler: {
        async received({eventType, detail}){
          console.log("RECEIVED")
        }
      }
    })

    async function loadEvents() {
      return await $api.apiV1Events.index({
        promotionId: promotion.value.slug
      })
    }

    async function load() {
      const res = await $api.apiV1Promotions.show({params: {id}})

      promotion.value = res.promotion
    }

    return {
      promotion,
      load,
      events,
      loadEvents

    }

  })

  return usePromotion()
}

export const usePromotions = defineStore('promotions', () => {
  const $api = useApi()
  const $endpoint = $api.apiV1Promotions
  const promotions = ref([])
  const events = useEvents()

  // $endpoint.onLoading((isLoading) => {
  //   console.log('loading promotions', isLoading)
  // })
  //
  // $endpoint.onResult((res) => {
  //   console.log('loaded promotions', res)
  // })


  function subscribe(slug) {
    const promotion = _findBySlug(slug)
    events.subscribeChannel(promotion.gid)
  }

  function unsubscribe(slug) {
    const promotion = _findBySlug(slug)
    events.unsubscribeChannel(promotion.gid)
  }

  async function index() {
    return await $endpoint.index({}, _updateAll)
  }

  async function show(id) {
    return await $endpoint.show({id}, _update)
  }

  async function create(promotion) {
    return await $endpoint.create({data: {promotion}}, _push)
  }

  async function update({id, ...promotion}) {
    return await $endpoint.update({data: {id, promotion}}, _update)
  }

  async function destroy(id) {
    return await $endpoint.destroy({id}, _remove)
  }

  function _update(res) {
    const { promotion: data } = res
    const promotion =  _find(data.id)

    promotion ? Object.assign(promotion, data) : _push(res)

    return res
  }

  function _updateAll(res) {
    promotions.value = res.promotions

    return res
  }

  function _push(res) {
    if (res) promotions.value.unshift(res.promotion)

    return res
  }

  function _findBySlug(slug) {
    const finder = (model) => model.slug === slug

    return promotions.value.find(finder)
  }

  function _find(id) {
    const finder = (model) => model.id === id

    return promotions.value.find(finder)
  }

  function _remove({id}) {
    const finder = (model) => model.id === id
    const idx = promotions.value.findIndex(finder)

    return promotions.value.slice(idx, 1)
  }

  return {
    promotions,
    subscribe,
    unsubscribe,
    events,
    index,
    show,
    create,
    update,
    destroy,
    findBySlug: _findBySlug,
    error: $endpoint.error,
    loading: $endpoint.loading
  }
})