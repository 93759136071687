import * as VueRouter from 'vue-router';
import { useAuth } from './stores/auth';
import { toValue } from 'vue';

function routes() {
  const views = import.meta.glob('./views/**/index.js', {eager: true})

  return Object.values(views).map((v) => v.route)
}

export const authGaurd = (to, from) => {
  if (to.meta.requiresAuth) {
    if (!toValue(useAuth().isAdmin)) {
      return false
    }
  }
}


export const createRouter = () => {
  const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes: [
      {path: '/', redirect: '/dashboard', name: 'rootRedirect'},
      ...routes()
    ]
  })

  router.beforeEach((to, from) => console.log(from.fullPath, ' -> ', to.fullPath) )
  router.beforeEach(authGaurd)

  return router
}


