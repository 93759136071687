import {computed, ref, watch} from "vue";
import {useRequestState} from "@/app/composable/useRequestState";
import {useCollection} from "@/app/composable/useCollection";
import {useApi} from "@/app/services/api";
import {defineStore} from "pinia";
import {mapEntries, partial} from "radash";
import {useAuth} from "@/app/stores/auth";
import {useArrayFilter} from "@vueuse/core";
import {useRoute} from "vue-router";


const baseStore = () => {
  const data = ref([])
  const collection = useCollection(data)
  const requestState = useRequestState()

  return { data, collection, requestState }
}

export const buildStore = (name, apiName, x_op={}) => {
  const {requestState, collection, data} = baseStore()
  const { loading, error, wrap } = requestState

  return defineStore(name, () => {
    const api = useApi()
    const actions = mapEntries(api[apiName], (fnName, fn) => {

      const transform = {
        index(_data) { collection.updateAll(_data[`${name}s`]) },
        create(_data) { collection.update(_data[`${name}`]) },
        update(_data) { collection.update(_data[`${name}`]) },
        destroy(_data) { collection.remove(_data[`${name}`]) },
      }

      for (const [op, op_fn] of Object.entries(x_op)) {
        transform[op] = op_fn.bind(transform)
      }

      const action = async (...args) => {
        const argFn = partial(fn, ...args)
        const result = await wrap(argFn)
        return transform[fnName](result)
      }

      return [fnName, action]
    })

    return {
      ...actions,
      data,
      loading,
      error
    }
  })
}

export const useEvents = buildStore('event', 'apiV1Events')
export const usePromotions = buildStore(
  'promotion', 
  'apiV1Promotions', 
  {
    activate(data) { this.index(data) },
    deactivate(data) { this.index(data) }
  }
)
export const useCalls = buildStore('call', 'apiV1Calls')
export const useUsers = buildStore('user', 'apiV1User')


// export const useEvents = () => {
//   const singularName = 'event'
//   const { collection, requestState, ...base } = baseStore()
//   const { loading, error } = requestState
//   const { apiV1Events } = useApi()
//   const actions = actionsBuilder('event')
//
//   return defineStore(`${singularName}s`, () => {
//     return {
//       loading,
//       error,
//       ...base,
//       ...createActions(
//         singularName,
//         apiV1Events,
//         collection,
//         requestState
//       )
//     }
//   })()
// }

// return defineStore(name, () => {
//   const data = ref([])
//   return {
//     data,
//     ...actions
//   }
// })

// function createActions(name, api, collection, requestState) {
//   return mapEntries(api, (action, fn) => ([
//     action,
//     requestState.wrap(
//       fn,
//       (res) => {
//
//         const data = res[name] || res[`${name}s`]
//
//         switch (action) {
//           case 'index':
//             collection.updateAll(data || []);
//             break;
//           case 'create':
//           case 'update':
//             collection.update(data);
//             break;
//           case 'destroy':
//             collection.remove(data);
//             break;
//           default:
//             console.warn(`No collection operation defined for action: ${action}`);
//         }
//
//         return data
//       })
//   ]))
// }


// export const useCalls = () => {
//   const singularName = 'call'
//   const { collection, requestState, data } = baseStore()
//   const { loading, error } = requestState
//   const { apiV1Calls } = useApi()
//   const auth = useAuth()
//
//   const eventMap = ref(new Map)
//
//   function byEvent(eventId) {
//     return eventMap.value.get(eventId)
//   }
//
//   function buildEventMap()  {
//     eventMap.value = new Map(
//       data.value.map((c) => [c.eventId, c])
//     )
//   }
//
//   watch(data, buildEventMap, {deep: true})
//
//
//   const placeCall = requestState.wrap(async ({id: spotId, eventId, wager = 1}) => {
//
//     const res = await apiV1Calls.create({
//       data: {
//         call: {
//           spotId,
//           userId: auth.userId,
//           wager: 1
//         }
//       }
//     })
//
//     collection.push(res.call)
//
//     return res.call
//   })
//
//   const active = useArrayFilter(data, (c) => ['placed', 'closed'].includes(c.state))
//
//   return defineStore(`${singularName}s`, () => {
//     return {
//       loading,
//       error,
//       ...base,
//       ...createActions(
//         singularName,
//         apiV1Calls,
//         collection,
//         requestState
//       ),
//       data,
//       byEvent,
//       eventMap,
//       active,
//       placeCall,
//     }
//   })()
// }
//
// export const usePromotion = defineStore('promo', () => {
//   const promo = ref({
//     title: '',
//     id: '',
//     slug: '',
//     gid: '',
//   })
//
//   // const events = useCollection('event')
//   const { apiV1Promotions } = useApi()
//   // const $eventApi = $api.apiV1PromotionsEvents
//   const route = useRoute()
//
//   async function load() {
//     const res = await apiV1Promotions.show({
//       params: {id: route.params.slug}
//     })
//
//     promo.value = res.promotion
//
//     // await $eventApi.index({
//     //   params: {
//     //     promotionId: res.promotion.slug
//     //   }
//     // }, events._updateAll)
//
//     // console.log(events.data)
//   }
//
//   // async function createEvent(event) {
//   //   console.log($eventApi)
//   //   try {
//   //     return await $eventApi.create({data: {event}}, events._push)
//   //   } catch(e) {
//   //     console.log('SOME ERROR', e)
//   //   }
//   // }
//
//   return {
//     promo,
//     load,
//     // events: events.data,
//     // createEvent,
//     // updateEvent: events._update
//   }
// })