<script setup>
import Chat from "@/app/components/Chat.vue";
import {useCurrentPromotion} from "@/app/views/promotions/store";
import {storeToRefs} from "pinia";

const store = useCurrentPromotion()
const { data } = storeToRefs(store)

</script>

<template>
  <Chat :contextId="data.gid" />
</template>

<style scoped>

</style>