<script setup>
import AdminDash from "@/app/views/dashboard/AdminDash.vue";
import UserDash from "@/app/views/dashboard/UserDash.vue";
import {useAuth} from "@/app/stores/auth";

const auth = useAuth()

</script>

<template>
<!--<AdminDash v-if="auth.isAdmin" />-->
<!--<UserDash v-else />-->

<router-view/>
</template>

<style scoped>

</style>