import {defineStore} from 'pinia';
import {ref} from "vue";
import {useApi} from "@/app/services/api";

import {useRequestState} from "@/app/composable/useRequestState";

export const useTransactions = defineStore('transactions', () => {

  const data = ref([])
  const $api = useApi()
  const state = useRequestState()

  const list = async function(id) {
    await state.wrap(async () => {
      const _data = await $api.apiV1Transactions.index()

      data.value = _data.transactions
    })
  }

  return {data, list, loading: state.loading, error: state.error}
})