import {defineStore} from "pinia";
import {createEventHook, reactiveComputed, toRefs, useArrayFilter} from "@vueuse/core";
import {reactive, ref, computed, onBeforeMount, onBeforeUnmount} from "vue";
import {useApi, useEndpoint} from "@/app/services/api";
import {useCollection} from "@/app/composable/useCollection";
import {useAuth} from "@/app/stores/auth";
import {useCalls} from "@/app/stores/calls";
import { usePromoChannelStore } from '@/app/stores/channel'
import {useDevtools} from "@/app/composable/useDevtools";




export const useCurrentPromotion = defineStore('currentPromotion', () => {
  const data = ref({})
  const $api = useApi().apiV1Promotions
  const devtools = useDevtools()

  const isLoading = ref(false)
  const eventStore = usePromotionEvents()
  const callStore = useCalls()
  const channel = usePromoChannelStore()

  const eventHistory = reactiveComputed(()  => {

    return eventStore.history.map((event) => {
      return {
        ...event,
        call: callStore.byEvent(event.id) || {wager: 1}
      }
    })
  })

  const events = reactiveComputed(()  => {

    return eventStore.action.map((event) => {
      return {
        ...event,
        call: callStore.byEvent(event.id) || {wager: 1}
      }
    })
  })

  const setCurrent = async (slug) => {
    devtools.emit('promotion.setCurrent', {data: { info: 'start', slug }})
    isLoading.value = true
    
    const { promotion } = await $api.show({params: {id: slug}})
    
    data.value = promotion

    channel.subscribe(promotion.gid)
    channel.on('*', (type, {event}) => {
      devtools.emit('promotions.channel', {data: {type, event}})
      eventStore.update(event)
    })

    await eventStore.index(promotion.id)

    isLoading.value = false
    devtools.emit('promotion.setCurrent', {data: { info: 'end', slug }})

    return promotion
  }

  return {
    setCurrent,
    data,
    events,
    eventHistory,
    channel,
    isLoading
  }
})

export const usePromotions = defineStore('promotions', () => {
  const $api = useApi().apiV1Promotions
  const { loading, error } = $api
  const {
    data,
    update: _update,
    find: _find,
    updateAll: _updateAll,
    findBy: _findBy
  } = useCollection({})

  const index = async () => await $api.index({}, _updateAll)

  const findOrFetch = async (id) => {
    return _find(id) ?? await $api.show({params: {id}}, _update)
  }

  const findBySlug = (slug) => {
    return _findBy((obj) => obj.slug === slug )
  }

  const create = () => {
    console.log('create promotion', data.value)
  }

  return {
    index,
    findBySlug,
    create,
    data,
    loading,
    error,
  }
})

export const usePromotionEvents = defineStore('promotion:events', () => {
  const {apiV1PromotionsEvents} = useApi()
  const auth = useAuth()
  const data = ref([])
  const { updateAll, update } = useCollection(data)


  // scoped computed props
  const actionStates = computed(() => auth.isAdmin ? ['open', 'draft', 'closed'] : ['open', 'closed'])
  const action = useArrayFilter(data, (e) => actionStates.value.includes(e.state))
  const historyStates = computed(() => auth.isAdmin ? ['open', 'draft'] : ['open'])
  const history = useArrayFilter(data, (e) => !historyStates.value.includes(e.state))

  const index = async (promotionId) => {
    const { events } = await apiV1PromotionsEvents.index(
      {
        params: {
          promotionId: promotionId
        }
      }
    )

    updateAll(events)
    return events
  }


  return {
    update,
    index,
    data,
    action,
    history
  }
})