import {defineStore} from "pinia";

export const useAuth = defineStore('auth', {
  state() {
    return {
      userRoles: [],
      userId: null,
      userEmail: null,
      csrfToken: null
    }
  },
  actions: {
    signIn(userDetails) {
      Object.assign(this.$state, userDetails)
      return this.$state
    },
    async signOut() {
      await fetch('/users/sign_out', {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': this.csrfToken
        }
      })

      window.location.href = "/users/sign_in"
    }
  },
  getters: {
    isAdmin() {
      return this.userRoles.includes('admin')
    }
  }
})