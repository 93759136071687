import { defineStore } from 'pinia'
import {inject, ref} from "vue";

import {useRequestState} from "@/app/composable/useRequestState";

export const useScore = defineStore('score', () => {
  const $api = inject('$api')
  const scores = ref([])
  const pager = ref()
  const state = useRequestState()

  async function list({promotion_id = null, page = 1}) {
    await state.wrap( async () => {
      const query = { page }

      if (promotion_id) query.promotion_id = promotion_id

      const res = await $api.apiV1Scores.index({query})

      scores.value = res.scores
      pager.value = res.meta
    })
  }

  return {
    scores,
    list,
    pager,
    loading: state.loading,
    error: state.error
  }
})