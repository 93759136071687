import Profile from "@/app/views/profile/Profile.vue";

export const route = {
  path: '/profile',
  component: Profile,
  name: 'profile',
  children: [
    { path: '/account', component: '' },
    { path: '/avatar', component: '' },
    { path: '/wallet', component: '' },
    { path: '/details', component: '' }
  ]
}