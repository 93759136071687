import * as routeClient from "@js-from-routes/client";
import {mapEntries} from "radash";
import {useRequestState} from "@/app/composable/useRequestState";
import {inject} from "vue";

export const definePathHelper = (method, pathTemplate) => {
  return routeClient.definePathHelper(method, pathTemplate)
}

export const createApiHandler = (endpoints) => {
  const {
    wrap,
    loading,
    error,
    onResult,
    onError,
    onLoading
  } = useRequestState()


  const actions = mapEntries(endpoints, (name, func) => {

    const { path, pathTemplate, httpMethod } = func
    const actionWrapper = async (params, transformer = (data) => data) => {

      const execApiAction = async () => {
        console.log('PARAMS', params)
        const result = await func(params);
        return transformer(result);
      }

      return await wrap(execApiAction);
    };

    actionWrapper.path = path
    actionWrapper.pathTemplate = pathTemplate
    actionWrapper.httpMethod = httpMethod

    return [name, actionWrapper]
  })

  return {
    ...actions,
    loading,
    error,
    onResult,
    onLoading,
    onError
  }
}