import {ref, computed, watch} from "vue";
import {defineStore} from "pinia";
import {useApi} from "@/app/services/api";
import {useAuth} from "@/app/stores/auth";
import {useRequestState} from "@/app/composable/useRequestState";


export const useCalls = defineStore('calls', () => {
  const data = ref([])
  const auth = useAuth()
  const $api = useApi()
  const state = useRequestState()
  const eventMap = ref(new Map)

  function byEvent(eventId) {
    return eventMap.value.get(eventId)
  }
  function buildEventMap()  {
    eventMap.value = new Map(
      data.value.map((c) => [c.eventId, c])
    )
  }

  watch(data, buildEventMap, {deep: true})

  const active = computed(() => {
    return data.value.filter((c) => {
      return ['placed', 'closed'].includes(c.state)
    })
  })

  async function placeCall({id: spotId, eventId, wager = 1}) {
    return await state.wrap(async () => {
      const res = await $api.apiV1Calls.create({
        data: {
          call: {
            spotId,
            userId: auth.userId,
            wager
          }
        }
      })

      data.value.push(res.call)

      return res
    })
  }

  async function index(id = null) {
    return await state.wrap(async () => {
      const res = await $api.apiV1Calls.index({query: { userId: id || auth.userId}})
      data.value = res.calls

      return data
    })
  }

  return {
    data,
    byEvent,
    eventMap,
    active,
    placeCall,
    index
  }
})
