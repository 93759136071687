// JsFromRoutes CacheKey 961f73b55c44aa736c90130acd01d6c3
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '~/app/services/apiFactory'

export default {
activate: /* #__PURE__ */ definePathHelper('put', '/api/v1/promotions/:id/activate'),
deactivate: /* #__PURE__ */ definePathHelper('put', '/api/v1/promotions/:id/deactivate'),
index: /* #__PURE__ */ definePathHelper('get', '/api/v1/promotions'),
create: /* #__PURE__ */ definePathHelper('post', '/api/v1/promotions'),
show: /* #__PURE__ */ definePathHelper('get', '/api/v1/promotions/:id'),
update: /* #__PURE__ */ definePathHelper('patch', '/api/v1/promotions/:id'),
destroy: /* #__PURE__ */ definePathHelper('delete', '/api/v1/promotions/:id'),
}