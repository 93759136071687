import {defineStore} from "pinia";
import {ref} from "vue";
import {useChannel} from "@/app/services/cable";
import {Config} from "@js-from-routes/client";
import {useDateFormat} from "@vueuse/core";
import {useAuth} from "@/app/stores/auth";
import {useApi} from "@/app/services/api";
import {useRequestState} from "@/app/composable/useRequestState";

class Message {
  constructor({content, user, createdAt, self}) {
    this.content = content;
    this.user = user;
    this.createdAt = useDateFormat(createdAt, 'hh:mm a');
  }
}

export function createChatStore(chatId) {
  return defineStore(`chat_${chatId}`, () => {
    const messages = ref([])
    const message = ref('')
    const state = useRequestState()
    const $api = useApi()
    const $endpoint = $api.apiV1Messages

    const { perform } =  useChannel({
      channel: 'ChatChannel',
      context_id: chatId,
      handler: {
        received: ({ detail: { message } }) => {

          const auth = useAuth()

          message = Config.deserializeData(message)


          messages.value.unshift(new Message({
            ...message,
            self: message.user.email === auth.userEmail
          }))
        }
      }
    })

    function send() {
      perform('speak', {msg: message.value})
      message.value = ''
    }

    async function list() {
      return await state.wrap( async () => {
        const res = await $endpoint.index({
          query: {contextId: chatId}
        })

        messages.value = res.messages
        return messages
      })

    }

    return {
      list,
      message,
      messages,
      send
    }
  })()
}