import {defineStore} from "pinia";
import {ref} from "vue";
import {useAuth} from "@/app/stores/auth";
import {useApi} from "@/app/services/api";
import {useCable} from "@/app/services/cable";
import {useRequestState} from "@/app/composable/useRequestState";

export const useNotifications = defineStore('notifications', () => {
  const $api = useApi()
  const $cable = useCable()
  const data = ref([])

  const summary = ref({
    unreadCount: 0
  })

  const state = useRequestState()

  async function index(userId) {
    await state.wrap( async () => {
      const res = await $api.apiV1Notifications.index({
        params: {
          id: userId
        }
      })

      data.value = res.notifications
      summary.value = res.meta
    })
  }

  async function clear(userId) {
    await state.wrap( async () => {
      const res = await $api.apiV1Notifications.destroy({
        params: {
          id: userId
        }
      })

      data.value = res.notifications
      summary.value = res.meta
    })
  }

  return {index, summary, data, clear}
})