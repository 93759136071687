<script setup>
import {ref, watch} from "vue";
import {clone} from "@/app/utils";

const model = defineModel()
const promotion = ref({})
const emits = defineEmits(['cancel'])

// copy props to control reactive update of model data
watch(
    () => model,
    () => {
      promotion.value = clone(model.value)
    },
    {immediate: true, deep: true}
)


const update = (_) => {
  model.value = promotion.value
}

function setDefaultEmbedUrl(e) {
  e.preventDefault();
  promotion.value.contentUrl = 'https://www.youtube.com/embed/live_stream?channel=UC04gdQ8px7juFmGRYYpn0Rg';
}


</script>

<template>
<form @submit.prevent="update">


  <fieldset class="fieldset">
    <legend>Detail</legend>

    <div class="field">
      <div class="mt-2">
        <label for="title"> Title </label>
        <input name='title'
               type="text"
               v-model="promotion.title"
        />
      </div>
    </div>

    <div class="field">
      <div class="mt-2">
        <label for='slug'> Slug </label>
        <input type="text"
               name="slug"
               v-model="promotion.slug"
        />
      </div>
    </div>

    <div class="field">
      <label for="contentUrl">Video Embed URL</label>
      <input type="text" name="contentUrl" v-model="promotion.contentUrl"/>
      <button @click="setDefaultEmbedUrl" class="btn btn--neutral mt-2 mr-auto ml-0">Use Call It Youtube Live Embed</button>
    </div>

    <!-- <div class="field field--checkbox">
      <label>
        <input type="checkbox" name="active" v-model="promotion.active" />
        <span class="ml-2">Active?</span>
      </label>
    </div> -->
  </fieldset>

  <!-- <fieldset class="fieldset">

    <legend>Schedule</legend>

    <div class="field">
      <label for="startat"> Start At </label>
      <input type="datetime-local"
             name="startat"
             v-model="promotion.startAt"
      />
    </div>

    <div class="field">
      <label for="endat"> End At </label>
      <input type="datetime-local"
             name="endat"
             v-model="promotion.endAt"
      />
    </div>

  </fieldset> -->

  <div class="button-group">
    <button class="button--negative" @click.prevent="emits('cancel')"> Cancel </button>
    <button class='button--positive' type="submit"> Save </button>
  </div>
</form>
</template>

<style scoped>
.button-group {
  @apply mt-6 flex flex-row items-center justify-end gap-x-6;
}

.button--positive {
  @apply rounded-md bg-call-it-500 px-3 py-2 text-sm
  font-semibold text-white shadow-sm hover:bg-call-it-400
  focus-visible:outline-offset-2 focus-visible:outline-call-it-500;
}

.button--negative {
  @apply text-sm font-semibold leading-6 text-white;
}

.fieldset {
  + .fieldset {
    @apply mt-8
  }

  legend {
    @apply
      text-xl text-ci2-brand
      font-bold
    ;
  }
}

.field {
  @apply
    block;

  + .field {
    @apply
      mt-4
  }

  label {
    @apply
      text-ci2-white
      font-bold
      tracking-wide
    ;
  }

  input:not([type="checkbox"]) {
    @apply
      w-full
  }
}
</style>