import {defineStore} from "pinia";
import {useApi} from "~/app/services/api";
import {ref} from "vue";

import {useRequestState} from "@/app/composable/useRequestState";

export const useUsers = defineStore('users', () => {
  const $api = useApi()
  const users = ref([])
  const state = useRequestState()

  async function index() {
    await state.wrap(async () => {
      const data = await $api.apiV1Users.index()

      users.value = data.users
    })
  }

  return {index, users, loading: state.loading, error: state.error}
})