<script setup>
import {computed, onUnmounted, reactive, ref} from "vue";
import ConfirmDialog from "@/app/components/events/ConfirmDialog.vue";
import {useEvents} from "@/app/stores/event";
import {useAuth} from "@/app/stores/auth";
import Spot from "./Spot.vue";
import {useCalls} from "@/app/stores/calls";
import {useCurrentUser} from "@/app/stores/currentUser";
import WagerCtrl from "@/app/components/events/WagerCtrl.vue";

const props = defineProps({
  id: Number,
  name: String,
  state: String,
  stateEvents: Array[String],
  result: Boolean,
  trueSpot: Object,
  falseSpot: Object,
  createdAt: String,
  updatedAt: String,
  publishedAt: String,
  resolvedAt: String
})

const store = useEvents()
const auth = useAuth()
const callStore = useCalls()
const currentUser = useCurrentUser()
const confirmDialog = ref(null)



const callModel = defineModel('call')

const c = reactive(callModel)


/**
 * @param {string} state
 * @param {boolean|null} result
 */
async function fireStateEvent(state, result=null) {
  await store.fireStateEvent({
    stateEvent: state,
    result: result,
    eventId: props.id
  })
}

async function confirmSpot({id, wager}) {
  await callStore.placeCall({eventId: props.id, id, wager})

  confirmDialog.value.close()
}


function launchConfirmDialog(state, el) {
  if (props.call?.state === 'placed') return

  confirmDialog.value.open({...state, ...props.call}, el)
}

onUnmounted(() => {
  currentUser.off(`call:resolve`, handleResolve)
  currentUser.off(`call:close`, handleClose)
})

// Event Bus
const handleResolve = (detail) => console.log(`call resolved ${props.id}`, detail)
const handleClose = (detail) => console.log(`call closed ${props.id}`, detail)

currentUser.on(`call:resolve`, handleResolve)
currentUser.on(`call:close`, handleClose)

// Computed

const isLive = computed(() => {
  return ['open'].includes(props.state)
})

const isWon = computed(() => {
  if (!(typeof props.call?.value === 'boolean')) return undefined
  if (!(typeof props.result === 'boolean')) return undefined

  return props.call?.value === props.result
})

const classList = computed( () => ({
  'state__open': props.state === 'open',
  'state__closed': props.state === 'closed',
  'state__draft': props.state === 'draft',
  'state__won': isWon.value,
  'state__lost': isWon.value === false
}))

</script>

<template>
<div :class="classList"
     class="event">
  <div class="event__info">
    <p class="name">
      {{name}}
    </p>

    <div v-if="auth.isAdmin" class="flex flex-row gap-4 items-center text-neutral-400">
      <h3 class="event-state text-md">
        <span> {{ $t(`ci.event.states.${state}`) }} </span>
      </h3>
      <h3 v-if="state === 'closed'">
        Result: <span> {{result}} </span>
      </h3>
      <h3 v-if="!resolvedAt && !publishedAt && createdAt" class="event-date text-sm">
        Created: <span> {{createdAt}} </span>
      </h3>
      <h3 v-if="!resolvedAt && publishedAt" class="event-date text-sm">
        Published: <span> {{publishedAt}} </span>
      </h3>
      <h3 v-if="resolvedAt" class="event-date text-sm">
        Resolved: <span> {{resolvedAt}} </span>
      </h3>
      <h3 v-if="updatedAt" class="event-date text-sm">
        Updated: <span> {{updatedAt}} </span>
      </h3>
    </div>
  </div>

  <!-- Transitions -->
  <div v-if="auth.isAdmin" class="event__transitions">
    <template v-for="stateEvent in stateEvents">
      <template v-if="stateEvent === 'close_event'">
        <div class="resolutions">
          <button class="btn btn--positive flex flex-col items-center justify-center md:flex-row md:justify-evenly" @click="fireStateEvent(stateEvent, trueSpot.value)" >
            <span class="text-lg md:text-xl lg:text-2xl"> {{trueSpot.label}} </span>

            <img v-if="trueSpot.imageMini" :src="trueSpot.imageMini" alt="resolve true spot icon" />
          </button>
          <button class="btn btn--positive flex flex-col items-center justify-center md:flex-row md:justify-evenly" @click="fireStateEvent(stateEvent, falseSpot.value)" >
            <span class="text-lg md:text-xl lg:text-2xl"> {{falseSpot.label}} </span>

            <img v-if="falseSpot.imageMini" :src="falseSpot.imageMini" alt="resolve false spot icon" />
          </button>
        </div>
      </template>
    
      <button v-else :class="`btn${stateEvent == 'hide' ? ' btn--negative' : ' btn--neutral'}`" @click="fireStateEvent(stateEvent)">
        <span class="text-lg md:text-xl lg:text-2xl"> {{ $t(`ci.event.events.${stateEvent}`) }} </span>
      </button>
    </template>
  </div>

  <!-- Wager Ctrl -->
  <!--  <template v-if="!auth.isAdmin && !call?.id && c">-->
  <!--  <WagerCtrl v-model:call="c.wager"/>-->
  <!--  </template>-->


  <!-- Spots -->
  <div v-if="!auth.isAdmin" class="event__spots">
    <Spot v-bind="trueSpot"
          :result="result"
          :call="call"
          @spot:click="launchConfirmDialog"
    />
    <Spot v-bind="falseSpot"
          :result="result"
          :call="call"
          @spot:click="launchConfirmDialog"
    />
  </div>

  <div class="event__confirm relative">
    <ConfirmDialog ref='confirmDialog' @confirm="confirmSpot"/>
  </div>
</div>
</template>

<style scoped>

  .event {
    @apply
      flex
      flex-col lg:flex-row
      flex-nowrap lg:flex-wrap
      justify-center items-center lg:items-stretch
      gap-2 lg:gap-0
      w-full max-w-7xl 

      mb-10
      ;
    
    + .event {
      @apply
        border-t-2 border-ci2-grey3
        pt-10
    }

    &.state {
      &__won {
        /* @apply border-2 border-amber-400; */
      }

      &__lost {
        /* @apply border-2 border-utility-error; */
      }

      &__open {
        /* @apply border-b-2 border-b-utility-success; */

        & .event-state {
          @apply text-utility-success;
        }
      }

      &__draft .event-state {
        @apply text-utility-warn;
      }

      &__closed .event-state {
        @apply text-utility-error;
      }
    }
  }

  .event__info {
    @apply
      basis-full lg:basis-2/3 
      grow
      /* w-full lg:w-auto */
      overflow-hidden
      
      flex flex-col justify-between gap-4

      /* px-10 py-8 */
      lg:py-4

      my-auto
    ;

    .name {
      @apply
        break-words text-2xl lg:text-2xl
        text-center lg:text-left
    }
  }

  .event__spots {
    @apply
      flex
      gap-4
      justify-center
      mt-4

      /* w-full */
      lg:w-auto lg:basis-1/3
    ;
  }

  .event__confirm {
    @apply
      w-full
      /* lg:w-1/2 */
  }

  .event__transitions {
    @apply
      flex gap-2 md:gap-4 lg:gap-6 basis-2/6 items-center
      py-8
    ; 

    .resolutions {
      @apply
        relative
        flex gap-2
      ;

      &::before {
        content: "Resolve:";
        @apply
          absolute
          top-[-1.75em]

          text-sm
      }
    }
  }
</style>