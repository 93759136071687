<script setup>
import PromotionForm from "@/app/views/promotions/PromotionForm.vue";
import {ref} from "vue";
import {useRouter} from "vue-router";
import {usePromotions} from "@/app/stores/promotion";
import { useDevtools } from "@/app/composable/useDevtools";

const promotion = ref({})
const router = useRouter()
const store = usePromotions()
const devtools = useDevtools();

const createPromotion = async (data) => {
  devtools.emit('promotions.create', {data: {data}})
  await store.create(data)

  promotion.value = {}
  router.push('/promotions')
}

const cancelCreate = () => {
  router.push('/promotions')
}

</script>

<template>
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <PromotionForm v-model="promotion"
                   @update:modelValue="createPromotion"
                   @cancel="cancelCreate()"
    />
  </div>
</template>

<style scoped>

</style>