// JsFromRoutes CacheKey 5479661f66a5d1ca1bfb3d0ec184289e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '~/app/services/apiFactory'

export default {
index: /* #__PURE__ */ definePathHelper('get', '/api/v1/events'),
create: /* #__PURE__ */ definePathHelper('post', '/api/v1/events'),
update: /* #__PURE__ */ definePathHelper('patch', '/api/v1/events/:id'),
destroy: /* #__PURE__ */ definePathHelper('delete', '/api/v1/events/:id'),
}