// JsFromRoutes CacheKey 26c387ce4e6e2ff1618042a73267dc6e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '~/app/services/apiFactory'

export default {
me: /* #__PURE__ */ definePathHelper('get', '/api/v1/users/me'),
index: /* #__PURE__ */ definePathHelper('get', '/api/v1/users'),
show: /* #__PURE__ */ definePathHelper('get', '/api/v1/users/:id'),
update: /* #__PURE__ */ definePathHelper('patch', '/api/v1/users/:id'),
}