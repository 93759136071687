import { toValue, ref } from 'vue'

// Likely temporary plugin?
// for "activating" promotions 
export const activePromotion = () => {

  const installed = ref(false)
  const activeSlug = ref(null)

  const shouldRedirect = (to, from) => {
    return (
      toValue(installed)
      && toValue(activeSlug)
      && (
        to.meta.redirectActive
        || (to.matched.some((r) => r.name === 'promotion' ) && to.params.slug !== toValue(activeSlug))
      )
    )
  }

  const gaurd = (to, from) => {
    console.log(
      shouldRedirect(to, from),
      to.matched.some((r) => r.name === 'promotion' ),
      to.params.slug !== toValue(activeSlug)
    )
    if (shouldRedirect(to, from)) {
      return { 
        name: 'promoAction',
         params: {
          slug: toValue(activeSlug) 
        } 
      }
    }
  }


  const uninstall = () => {
    installed.value = false
  }

  return {
    installed,
    activeSlug,
    shouldRedirect,
    uninstall,
    install(app) {
      const { _props: props, config: { globalProperties: { $router } } } = app
  
      app.provide('$activePromotion', this)
      app.config.globalProperties.$activePromotion = this
  
      $router.beforeEach(gaurd)
  
      if (!props.userRoles.includes('admin') && props.activePromotion) {
        activeSlug.value = props.activePromotion
        installed.value = true
      }
    }
  }
}
