<script setup>
import {onMounted, reactive, ref} from "vue";
import {useEvents} from "@/app/stores/event";
import {useApi} from "@/app/services/api";
import {useUpload} from "@/app/services/useUpload";
import {CameraIcon, PhotoIcon} from "@heroicons/vue/24/outline";
import {unrefElement, useActiveElement, useWindowFocus} from "@vueuse/core";
import {promotionStore} from "@/app/stores/promotion";

const initialState = () => ({
  name: undefined,
  trueOutcome: {
    label: undefined,
    odds: undefined,
    image: undefined
  },
  falseOutcome: {
    label: undefined,
    odds: undefined,
    image: undefined
  }
})
const props = defineProps(['promotionId'])
const emits = defineEmits(['created'])
const data = reactive(initialState())
const store = useEvents()
const $api = useApi()
const {onChange, onComplete} = useUpload()
const focusEl = ref(null)
onMounted(() => unrefElement(focusEl).focus())

onComplete(({name, blob}) => {
  const signedId = blob.signed_id
  data[`${name}Preview`] = $api.apiV1StorageBlobRedirects.show.path({signedId: signedId})
  data[name] = signedId
})

// store.create.onError((err) => {
//   console.log('SOME ERROR HAS HAPPENED', err)
// })

async function submitEvent(event) {
  await store.create({...data, promotionId: props.promotionId })
  resetForm()
}

function resetForm() {
  Object.assign(data, initialState())
  emits('created')
}
</script>

<template>
  <form class='form' name="newEvent" @submit.prevent='submitEvent'>
    <legend tabindex='-1' class="form-title">
      New Event
    </legend>

    <div class="field">
      <label for="name" class="text-sm font-medium leading-6 text-white">Question Label</label>
      <input id="name"
              ref="focusEl"
              tabindex="0"
              name='name'
              v-model="data.name"
              type="text">
    </div>

    <fieldset class="fieldset">
      <legend>True Spot</legend>

      <!-- <div class="field col-span-1 sm:col-span-2">
        <label for="odds" class="block text-sm font-medium leading-6 text-white"> Odds </label>
        <div class="mt-2">
          <input id="odds"
                 tabindex="0"
                 name='name'
                 v-model="data.trueOutcome.odds"
                 type="text">
        </div>
      </div> -->

      <div class="field field--file">
        <label for="trueImage"> Image </label>

        <div class="input-wrap">
          <PhotoIcon class="icon" />
          <input
                  id="trueImage"
                  name='trueImage'
                  @change="onChange"
                  type="file">
        </div>

        <span v-if=data.trueImagePreview>
          <img :src="data.trueImagePreview" alt="True spot image preview">
        </span>
      </div>

      <div class="field">
        <label for="trueLabel"> Label </label>
        <input id="trueLabel"
                name='trueLabel'
                v-model="data.trueOutcome.Label"
                type="text">
      </div>

    </fieldset>

    <fieldset class="fieldset">

      <legend>False Spot</legend>


      <!-- <div class="field col-span-1 sm:col-span-2">
        <label for="odds" class="block text-sm font-medium leading-6 text-white"> Odds </label>
        <div class="mt-2">
          <input id="odds"
                 tabindex="0"
                 name='name'
                 v-model="data.falseOutcome.odds"
                 type="text">
        </div>
      </div> -->

      <div class="field field--file">
        <label for="falseImage"> Image </label>

        <div class="input-wrap">
          <PhotoIcon class="icon" />
          <input id="falseImage"
                  name='falseImage'
                  @change="onChange"
                  type="file">
        </div>

        <span v-if=data.falseImagePreview>
          <img :src="data.falseImagePreview" alt="False spot image preview">
        </span>
      </div>

      <div class="field">
        <label for="falseLabel"> Label </label>
        <input id="falseLabel"
                name='falseLabel'
                v-model="data.falseOutcome.label"
                type="text">
      </div>

    </fieldset>

    <div class="actions">
      <button type="submit" class="btn--positive">Submit</button>
    </div>

  </form>
</template>

<style scoped>

.form {
  .form-title {
    @apply
      text-3xl text-call-it-900 font-semibold
  }
}

.fieldset {
  @apply
    border-l border-white/10 mt-6 mb-2 col-span-1 sm:col-span-2
  ;

  legend {
    @apply
      block
      bg-call-it-300
      text-ci-primary
      px-2
  }

  .field {
    @apply pl-4
  }
}

.field {
  @apply my-4;

  label {
    @apply
      block text-sm font-medium leading-6 text-white
  }

  input {
    @apply
      mt-2 w-full 
  }
}

.field--file {
  .input-wrap {
    @apply
      flex items-center gap-x-3;

    
      .icon {
        @apply
          mx-auto h-12 w-12 text-gray-500;
      }
  }

  input {
    @apply 
      block py-1.5 
  }
}



.actions {
  @apply mt-6;
}
</style>