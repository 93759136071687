import {ref} from "vue";


export function useCollection(data, {name='collection'} = {}) {
  function update(_data) {
    const model =  find(_data.id)

    console.log('update collection', model, _data.id)

    model ? Object.assign(model, _data) : push(_data)

    return _data
  }

  function updateAll(_data) {
    data.value = _data

    return _data
  }

  function push(_data) {
    if (_data) data.value.unshift(_data)

    return _data
  }

  function findBy(fn) {
    return data.value.find(fn)
  }

  function find(id) {
    return findBy((model) => model.id === id)
  }

  function remove({id}) {
    const finder = (model) => model.id === id
    const idx = data.value.findIndex(finder)

    return data.value.slice(idx, 1)
  }

  return {
    updateAll,
    update,
    find,
    findBy,
    remove,
    push
  }
}
