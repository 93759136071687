<template>
	<div class="page">
		<header class="page__header">
			<h1 class="title">
				<slot name="title"></slot>
			</h1>
			<div class="actions">
				<slot name="actions"></slot>
			</div>
		</header>
		<main class="page__body">
			<slot></slot>
		</main>
	</div>
</template>

<style scoped>

.page {
	@apply
		max-w-7xl
		mx-auto
		px-4 sm:px-6 lg:px-8
		;
}

.page__header {
  	@apply
    	flex justify-between items-center 
		py-2 lg:pb-6
		;

	.title {
    	@apply
    		text-3xl font-bold text-gray-400;
  	}
  
	.btn {
		@apply mr-0
	}
}

.page__body {}

</style>