<script setup>
  import { onMounted, ref } from "vue";
  import AdminLink from "@/app/components/admin/AdminLink.vue";
  import { usePromotions } from "@/app/stores/base";
  import Page from "../../components/Page.vue";
  import {Dialog} from "@/app/components";
  import PromotionForm from "./PromotionFormSingle.vue";
  import PromotionEdit from "./PromotionEdit.vue";
  import {useAuth} from "@/app/stores/auth";

  const promotions = usePromotions()
  const auth = useAuth()

  onMounted(async () => {
    const p = promotions.index()
  })

  // const promoNewOpen = ref(false);
  const promoEditOpen = ref(false);

  const activatePromotion = async (promotion) => {
    console.log('Activating promotion', promotion)
    await promotions.activate(promotion)
  }

  const deactivatePromotion = async (promotion) => {
    console.log('Activating promotion', promotion)
    await promotions.deactivate(promotion)
  }

</script>

<template>

  <Page>
    <template #title>Promotions</template>
    <template #actions v-if="auth.isAdmin">
      <admin-link to="/promotions/new" class="btn btn--positive">Add New</admin-link>
      <!-- <a href="#" @click.prevent="promoNewOpen = true" class="btn btn--positive">Add New</a> -->
    </template>

    <template v-if="promotions.loading">
        Loading...
    </template>

    <template v-else>
      <div class="promotions-index">
        <router-link :to="`/promotions/${promo.slug}`" v-for="promo in promotions.data" class="promotion">
          <div class="promotion__detail">
            <h2 class="text-lg font-semibold">
              {{ promo.title }}
            </h2>
            <!-- <p class="text-gray">{{ promo.summary }}</p> -->
          </div>
          <div class="promotion__actions">
            <!-- <template v-if="promo.registrationType === 'open_registration'">
              <router-link :to="`/promotions/${promo.slug}`"
                          class="text-primary hover:underline">
                View
              </router-link>
            </template> -->
            <!-- <template v-if="promo.registrationType === 'closed_registration'">
              <router-link :to="`/promotions/${promo.slug}`"
                          class="text-primary hover:underline">
                Register
              </router-link>
            </template> -->
            <template v-if="auth.isAdmin">
              <button @click.prevent='activatePromotion(promo)'
                      v-if="!promo.active"
                      class="btn btn--positive">
                Activate
              </button>
              <button @click.prevent.confirm='deactivatePromotion(promo)'
                      v-if="promo.active"
                      class="btn btn--positive">
                Deactivate
              </button>
            </template>
            <admin-link :to="`/promotions/${promo.slug}/edit`"
                        class="btn btn--neutral">
              Edit
            </admin-link>
          </div>
        </router-link>
      </div>
    </template>

  </Page>

  <!-- <Dialog v-model:open="promoNewOpen">
    <PromotionForm
      @created="promoNewOpen = false"
      :promotionId="store.data.id" 
      />
    <PromotionForm />
  </Dialog> -->

</template>

<style scoped>
  .promotions-index {
    @apply
      grid grid-cols-1 gap-4
  }

  .promotion {
    @apply
      flex items-center justify-between
      p-4 

      border-2 border-ci-tertiary
      bg-ci-secondary
      rounded
      
      hover:no-underline
      ;
  }

  .promotion__actions {
    @apply
      flex items-center space-x-2
  }
</style>