import PromotionShow from "@/app/views/promotions/PromotionShow.vue";
import PromotionCreate from "@/app/views/promotions/PromotionCreate.vue";
import PromotionEdit from "@/app/views/promotions/PromotionEdit.vue";
import PromotionIndex from "@/app/views/promotions/PromotionIndex.vue";
import Action from "@/app/views/promotions/Action.vue";
import Leaders from "@/app/views/promotions/Leaders.vue";
import Chat from "@/app/views/promotions/Chat.vue";
import CallHistory from "@/app/views/promotions/CallHistory.vue";
import {usePromotions} from "@/app/stores/promotion";
import Registration from "@/app/views/promotions/Registration.vue";
import {useCurrentUser} from "@/app/stores/currentUser";

const requiresRegistration = ({registrationType}) => {
  return registrationType === 'closed_registration'
}

const checkPromotionRegistrationType = async (to, from) => {
  const promotions = usePromotions(),
        current_user = useCurrentUser(),
        slug = to.params.slug

  let promotion = await promotions.findBySlug(slug)
  console.log('FIND BY SLUG', slug, promotion)
  // if (requiresRegistration(promotion)) {
  //   console.log('requires registration')
  // }
}

export const route = {
  path: '/promotions',
  // component: Layout,
  children: [
    // Public
    {
      path: '',
      name: 'promotions',
      component: PromotionIndex,
      meta: { redirectActive: true }
    },
    {
      path: ':slug',
      name: 'promotion',
      component: PromotionShow,
      // beforeEnter: checkPromotionRegistrationType,
      children: [
        {
          path: 'join',
          name: 'promotionRegistration',
          component: Registration,
          meta: { redirectActive: true }
        },
        {
          path: '',
          name: 'promoAction',
          component: Action
        },
        {
          path: 'leaders',
          name: 'promoLeaders',
          component: Leaders,
        },
        {
          path: 'chat',
          name: 'promoChat',
          passProps: true,
          component: Chat,
          meta: { redirectActive: true }
        },
        {
          path: 'history',
          name: 'promoHistory',
          component: CallHistory
        }
      ]
    },

    // Admin
    {
      path: ':id/edit',
      name: 'promoEdit',
      component: PromotionEdit,
      meta: { requiresAuth: true }
    },
    {
      path: 'new',
      name: 'promoCreate',
      component: PromotionCreate,
      meta: { requiresAuth: true }
    },
  ]
}