import {defineStore} from 'pinia';
import {ref, toValue, computed} from "vue";
import {useApi} from "@/app/services/api";
import {useCable} from "@/app/services/cable";
import {omit} from "radash";
import {useAuth} from "@/app/stores/auth";
import {useRoute} from "vue-router";
import {useCollection} from "@/app/composable/useCollection";
import {useArrayFilter} from "@vueuse/core";
import {useRequestState} from "@/app/composable/useRequestState";
import { useDevtools } from "@/app/composable/useDevtools";


export const useEvents = defineStore('events', () => {
  const $api = useApi()
  const $endpoint = $api.apiV1Events
  const $promoEventsApi = $api.apiV1PromotionsEvents
  const $cable = useCable()
  const devtools = useDevtools()

  const events = useCollection('event')

  const auth = useAuth()
  const route = useRoute()

  // scoped computed props
  const actionStates = computed(() => auth.isAdmin ? ['open', 'draft', 'closed'] : ['open', 'closed'])
  const action = useArrayFilter(events.data, (e) => actionStates.value.includes(e.state))
  const historyStates = computed(() => auth.isAdmin ? ['open', 'draft'] : ['open'])
  const history = useArrayFilter(events.data, (e) => !historyStates.value.includes(e.state))

  //crud
  async function list(query = {}) {
    return await $promoEventsApi.index({params: {promotionId: route.params.slug}}, events._updateAll)
  }

  async function create(event) {
    try {
      return await $endpoint.create({data: {event}}, events._push)
    } catch(e) {
      console.log('SOME ERROR', e)
    }
  }

  async function fireStateEvent({eventId, ...data}) {
    devtools.emit('event.fire', {data: {eventId, data}})
    return await $api.apiV1States.update({params: {eventId}, data})
  }

  return {
    events: events.data,
    action,
    history,
    create,
    list,
    fireStateEvent,
    updateEvent: events._update,
    loading: $endpoint.loading,
    error: $endpoint.error
  }
})
