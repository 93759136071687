<script setup>
import {Popover, PopoverButton, PopoverPanel, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {BellIcon} from "@heroicons/vue/24/outline";
import {useNotifications} from "@/app/stores/notification";
import {onMounted} from "vue";
import {useAuth} from "@/app/stores/auth";
import {storeToRefs} from "pinia";


const notifications = useNotifications()
const user = useAuth()

const {summary} = storeToRefs(notifications)

onMounted(async () => {
  await notifications.index(user.userId)
})



</script>

<template>
  <Popover class="relative" v-slot="{ open }">
    <PopoverButton>
      <span v-if="summary.unreadCount" class="unread-count">{{summary.unreadCount}}</span>
      <button type="button"
              @click="notifications.clear(user.userId)"
              class="relative rounded-full ml-auto bg-ci-primary p-1 text-gray-600 hover:text-gray-400
                                 focus:outline-none focus:ring-2 focus:ring-call-it-500 focus:ring-offset-2">
        <span class="absolute -inset-1.5" />
        <span class="sr-only">View notifications</span>
        <BellIcon class="h-6 w-6" aria-hidden="true" />
      </button>
    </PopoverButton>

    <TransitionRoot as="template" :show="open">
      <PopoverPanel as="div"
                    class="absolute z-10 right-0 mt-2 w-48
                                       rounded-md bg-ci-secondary
                                       py-1 shadow-lg ring-1 ring-gray-400 ring-opacity-5 focus:outline-none">
        <TransitionChild
            as="div"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-1"
            enter-to="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0"
            leave-to="opacity-0 translate-y-1">

          <div class="notification" :class="{'notification--unread': !notification.read}"
               v-for="notification in notifications.data">
            <!-- {{notification.notableId}} -->
            <!-- {{notification.notableType}} -->
            {{notification.eventType}}
            <!-- {{notification.createdAt}} -->
            <div class="notification__time">{{notification.updatedAt}}</div>
          </div>
        </TransitionChild>
      </PopoverPanel>
    </TransitionRoot>
  </Popover>
</template>

<style scoped>

.unread-count {
  @apply
    absolute top-[-0.3rem] right-[-0.3rem] z-10 

    w-4 h-4
    
    text-xs font-light text-ci2-white
    
    rounded-full bg-ci2-brand
  ;
}

.notification {
  @apply
    block 
    relative
    pl-6 py-2 pr-4 
    text-sm text-gray-300
    ;
}
.notification--unread {
  &::before {
    content: "";
    @apply
      block
      absolute
      left-[7px]
      top-[15px]
      w-2 h-2
      rounded-full
      bg-ci2-brand
    ;
  }
}

.notification__time {
  @apply
    text-xs italic
}

</style>