<script setup>

import {computed, ref, watch, toValue} from "vue";
import {useRoute} from "vue-router";
import {useAuth} from "@/app/stores/auth";
import RibbonIcon from "@icons/ribbon--solid.svg?component";
import {PlusIcon, PencilIcon, ChevronRightIcon, BoltIcon, ChatBubbleLeftRightIcon, TrophyIcon, ListBulletIcon} from "@heroicons/vue/24/solid";
import Page from "../../components/Page.vue";
import EventForm from "@/app/components/events/EventForm.vue";
import {Dialog} from "@/app/components";
import {useCurrentPromotion} from "@/app/views/promotions/store";
import { storeToRefs } from "pinia";
import { tryOnBeforeUnmount } from "@vueuse/core/index.cjs";

const props = defineProps(["slug"])
const auth = useAuth()
const route = useRoute()
const store = useCurrentPromotion()
const eventFormOpen = ref(false)

const { isLoading, data } = storeToRefs(store);

const onRoute = () => {
  store.setCurrent(route.params?.slug ?? props.slug)
}

watch(
  () => {
    return route
  },
  () => onRoute(),
  {immediate: true}
)

tryOnBeforeUnmount(() => {
  store.channel?.unsubscribe()
})

const contentSrc = computed(() => {
  return data?.value?.contentUrl ?? null
  // return 'https://drive.google.com/file/d/1qO3ZdmHinqEEHan5qsH6K9hfoEMGUGQb/preview'
})

const navigationItems = [
  {
    icon: BoltIcon,
    to: {name: 'promoAction', params: route.params},
    label: 'Action',
  }, 
  {
    icon: TrophyIcon,
    to: {name: 'promoLeaders', params: route.params},
    label: 'Leaders',
    meta: { admin: true }
  },
  // {
  //   icon: ChatBubbleLeftRightIcon,
  //   to: {name: 'promoChat', params: route.params},
  //   label: 'Chat'
  // },
  {
    icon: ListBulletIcon,
    to: {name: 'promoHistory', params: route.params},
    label: 'History'
  }
]

const navigation = computed(() => {
  return navigationItems.filter((item) => {
    if (toValue(auth.isAdmin)) {
      return true
    }
     
    if (item.meta?.admin) {
      return false
    }

    return true
  })
})

</script>

<template>
  <!-- <Page> -->
    <!-- <template #title>
      {{ store.data.title }}
    </template> -->
    <div class="promotion">

      <nav class="promotion__admin" v-if="auth.isAdmin">
        <ul class="flex flex-row flex-nowrap gap-6">
          <li v-if="auth.isAdmin" class="ml-auto">
            <router-link :to="`/promotions/${store.data.slug}/edit`"
                        class="flex items-center gap-x-1.5 px-2.5 py-1.5 font-semibold text-gray-400">
              <PencilIcon class="-ml-0.5 h-5 w-5" /> <span class="hidden lg:block"> Edit </span>
            </router-link>
          </li>

          <li v-if="auth.isAdmin">
            <a href='#' @click.prevent="eventFormOpen = true" class="flex items-center gap-x-1.5 px-2.5 py-1.5 font-semibold text-gray-400">
              <PlusIcon class="-ml-0.5 h-5 w-5" /> <span class="hidden lg:block"> New Event </span>
            </a>
          </li>
        </ul>
      </nav>

      <nav class="promotion__navbar">
        <ul>
          <li v-for="item in navigation">
            <router-link :to="item.to">
              <component :is="item.icon" class="icon" />
              <span class="label">{{item.label}}</span>
            </router-link>
          </li>
        </ul>
      </nav>

      <div class="promotion__hero">
        <div class="video" v-if="contentSrc">
          <iframe :src=contentSrc allow="autoplay"></iframe>
        </div>
        <div class="graphic" v-else>
          <img src="@images/temp/the-beez.png" />
        </div>
      </div>

      <Dialog v-model:open="eventFormOpen">
        <EventForm v-if="auth.isAdmin"
                  @created="eventFormOpen = false"
                  :promotionId="store.data.id"></EventForm>
      </Dialog>

      <template v-if="isLoading"> 
        <div class="flex h-full">
          <span class="m-auto text-2xl text-center w-3/4">You're early! <br/> Check back soon to get in on the action.</span>
        </div>
      </template>
      <template v-else>
        <div class="promotion__view">
          <div class="view">
            <router-view></router-view>
          </div>

      </div>
    </template>

  </div>
  <!-- </Page> -->
</template>

<style scoped>

.router-link-exact-active {
  @apply text-ci2-brand underline;
}

.promotion {
  @apply
    flex flex-col
    h-[calc(100vh-4.5rem)]
    
    xl:flex-row xl:flex-wrap
    xl:pt-10
    ;
}

.promotion__hero {
    @apply
      sm:px-32
      md:px-52
      lg:px-60
      xl:px-4 

      xl:basis-1/2
    ;

    .graphic {
      img {
        @apply
          w-full
        ;
      }
    }
}

.promotion__view {
  @apply
    grow
    overflow-y-auto
    px-4
    pt-6
    pb-[100px] md:pb-[120px] /*space for navbar + a little...*/ 

    xl:basis-1/2
    xl:pt-4
    
    flex
    ;
  .view {
    @apply
      w-full
      m-auto
  }
}

.promotion__navbar {
  @apply
    fixed
    right-0 bottom-0 left-0
    z-20

    lg:right-auto lg:left-1/2 lg:translate-x-[-50%]

    bg-ci2-black
    shadow-2xl shadow-ci2-grey2
  ;
  
  ul {
    @apply
      flex
  }

  li {
    @apply
      basis-1/4
      grow;

      &:not(:first-child) {
        @apply
          border-l border-ci2-grey3
      }

      a {
        @apply
          block
          p-4 sm:p-6 lg:px-10

          transition-colors
          /* hover:bg-ci-accent */
          
      }
      .icon {
        @apply
          m-auto
          w-6 h-6
      }
      .label {
        @apply
          block
          mt-2
          text-center

          text-xs md:text-base

      }
  }
}

.video {
  @apply

    relative
    pb-[56.25%]
    h-0
    ;

    iframe {
      @apply
        absolute
        top-0 left-0
        w-full h-full
        /* py-4 */
        /* shadow-2xl shadow-ci2-grey2 */
    }
}
</style>