import {ref} from "vue";
import {createEventHook} from "@vueuse/core";

export const useRequestState = () => {
  const loading = ref(false)
  const error = ref(null)

  const wrap = async (fn) => {
    loading.value = true
    error.value = null
    try {
      const res = await fn()
      return res
    } catch(e) {
      error.value = e
      throw(e)
    } finally {
      loading.value = false
    }
  }

  return {
    wrap,
    loading,
    error
  }
}