<script setup>

import Leaderboard from "@/app/components/Leaderboard.vue";
import {useRoute} from "vue-router";
import {useScore} from "@/app/stores/score";
import {watchEffect} from "vue";

const route = useRoute()
const store = useScore()

watchEffect(() => {
  store.list({
    promotion_id: route.params.slug,
    page: route.query.page
  })
})

</script>

<template>
  <Leaderboard />
</template>

<style scoped>

</style>