<script setup>
import {computed, toValue} from 'vue'
import {useAuth} from "@/app/stores/auth";

const props = defineProps(['navigation'])

import { useMenu } from '@/app/menu';
const { navigation } = useMenu()

const linkClassList = (isActive, isExactActive) => {
  return [
    isActive
        ? 'router-link-active border-call-it-500 text-gray-200'
        : 'border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-300',
    isExactActive
        ? 'router-link-exact-path-active'
        : '',
    'inline-flex items-center gap-x-2 border-b-2 px-2 text-sm font-medium hover:no-underline'
  ]
}

const auth = useAuth()

const links = computed(() => {
  if (toValue(auth.isAdmin)) {
    return toValue(navigation)
  }

  return []
  // TODO unhide this under normal conditions
  // return navigation.filter((l) => {
  //   return !l.admin
  // })
})

</script>

<template>
<div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8 overflow-hidden">

  <template
      v-for='item in links'
  >
    <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        :to="item.url"
        custom
    >
      <a @click="navigate"
         :aria-current="isActive ? 'page' : undefined"
         :href="href"
         :class="linkClassList(isActive, isExactActive)">
        <component :is="item.icon" class="mx-auto w-5 h-5"/>
        <span> {{item.label}} </span>
      </a>
    </router-link>
  </template>
</div>
</template>

<style scoped>

</style>