<script setup>
import {computed} from "vue";
import {useAuth} from "@/app/stores/auth";
import {Disclosure, DisclosureButton} from "@headlessui/vue";
import {XMarkIcon} from "@heroicons/vue/16/solid";
import {Bars3Icon} from "@heroicons/vue/24/outline";

import Brand from "~/app/Brand.vue";
import TopLevelNav from "~/app/menu/TopLevelNav.vue";
import ProfileMenu from "~/app/menu/ProfileMenu.vue";
import MobileMenu from "~/app/menu/MobileMenu.vue";
import Notifications from '@/app/components/NotificationList.vue';
import {useCurrentUser} from "@/app/stores/currentUser";
import BalanceIndicator from "@/app/menu/BalanceIndicator.vue";

const props = defineProps({
  userGid: String,
  userId: Number,
  userEmail: String,
  userRoles: Array,
  csrfToken: String,
  csrfParam: String,
})

const currentUser = useCurrentUser()
const auth = useAuth()

auth.signIn({...props})

</script>

<template>
  <div class="h-full">
    <Disclosure as="nav" class="bg-ci2-black" v-slot="{ open }">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-16">
          <!-- Branding + Top Nav -->
          <div class="mr-auto sm:mr-0 flex items-center py-2">
            <a href="/promotions" class="block w-auto h-full">
              <Brand />
            </a>
          </div>
          <TopLevelNav class="mr-auto" />
          <!-- Notifications + Profile -->
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <!-- <BalanceIndicator /> -->
            <!-- Notification dropdown -->
            <Notifications />
            <!-- Profile dropdown -->
            <ProfileMenu />
          </div>
          <!-- Mobile menu button -->
          <div class="-mr-2 flex items-center sm:hidden">
            <!-- Hidden above sm-->
            <DisclosureButton class="relative inline-flex items-center justify-center rounded-md bg-secondary p-2 text-gray-600 hover:bg-gray-800 hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-call-it-500 focus:ring-offset-2">
              <!-- <span class="absolute -inset-0.5" /> -->
              <span class="sr-only">Open main menu</span>
              <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>
      <!--  Hidden above sm  -->
      <MobileMenu />
    </Disclosure>
    <!-- Main View -->
    <!-- <div class="h-full py-2 md:py-6 lg:py-10"> -->
      <router-view />
    <!-- </div> -->
  </div>
</template>

<style scoped>

</style>