<script setup>

import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import CurrentAvatar from "@/app/components/CurrentAvatar.vue";
import {useAuth} from "@/app/stores/auth";
import {useCurrentUser} from "@/app/stores/currentUser";

import { useMenu } from '@/app/menu';
const { userNavigation } = useMenu()

const auth = useAuth()
</script>

<template>
<Menu as="div" class="relative ml-3">
  <div>
  <MenuButton class="relative flex max-w-xs items-center rounded-full bg-ci-secondary text-sm focus:outline-none focus:ring-2 focus:ring-call-it-500 focus:ring-offset-2">
<!--    <span class="absolute -inset-1.5" />-->
    <span class="sr-only">Open user menu</span>
    <CurrentAvatar size="thumb" class="mx-auto h-8 w-8 text-gray-500" />
  </MenuButton>
  </div>
  <transition enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
    <MenuItems
        class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-ci-secondary
                         py-1 shadow-lg ring-1 ring-gray-400 ring-opacity-5 focus:outline-none">
      <MenuItem
          v-for="item in userNavigation"
          as="template"
          :key="item.label"
          v-slot="{ active }"
      >
        <router-link :to="item.url" :class="[active ? 'bg-gray-800' : '', 'block px-4 py-2 text-sm text-gray-300']">
          {{ item.label }}
        </router-link>
      </MenuItem>

      <MenuItem as="template" v-slot="{ active }">
        <a href='#'
           @click.prevent="auth.signOut()"
           :class="[
               active
               ? 'bg-gray-800'
               : '',
                'text-left block px-4 py-2 text-sm text-gray-300'
                ]">
          Logout
        </a>
      </MenuItem>
    </MenuItems>
  </transition>
</Menu>

</template>

<style scoped>

</style>